const originalFetch = fetch;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const refreshTokens = async () => {
  try {
    let isSuperAdmin = false;
    let userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (!userDetails) {
      isSuperAdmin = true;
      userDetails = JSON.parse(localStorage.getItem('superAdminUser'));
    }
    const refreshToken = userDetails?.session?.refreshToken || null;

    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await originalFetch(
      `${process.env.REACT_APP_DEV_API}/v1/iam/update-session`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refreshToken })
      }
    );

    const data = await response.json();

    if (data.status === 'success') {
      if (isSuperAdmin) {
        localStorage.setItem('superAdminUser', JSON.stringify(data.data));
      } else {
        localStorage.setItem('userDetails', JSON.stringify(data.data));
      }
      processQueue(null, data.data.session.accessToken);
      return data.data.session.accessToken;
    } else {
      throw new Error(data.message || 'Failed to refresh tokens');
    }
  } catch (error) {
    processQueue(error, null);
    console.error('Error refreshing tokens:', error);
    localStorage.clear();
    window.location.href = '/login';
    return false;
  }
};

window.fetch = async (...args) => {
  try {
    const response = await originalFetch(...args);

    if (response.status === 401) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            const [url, config] = args;
            const newConfig = {
              ...config,
              headers: {
                ...config?.headers,
                Authorization: `Bearer ${token}`
              }
            };
            return originalFetch(url, newConfig);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      try {
        const newToken = await refreshTokens();
        isRefreshing = false;
        const [url, config] = args;
        const newConfig = {
          ...config,
          headers: {
            ...config?.headers,
            Authorization: `Bearer ${newToken}`
          }
        };
        return originalFetch(url, newConfig);
      } catch (refreshError) {
        isRefreshing = false;
        return Promise.reject(refreshError);
      }
    }

    return response;
  } catch (error) {
    console.error('Fetch error:', error);
    return Promise.reject(error);
  }
};
