import React, { useState, useContext, useEffect } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import axios from 'axios';
import WindowCard from './ui/windowCard';
import ApplicationLineChart from './ui/charts/LineChartApplication.js';
import CanvasPieChartTab from './ui/charts/PieChartApplicationDistribution.js';
import ApplicationStatusBarChart from './ui/charts/BarChartApplicationStatus.js';
import PreAssesmentStatusChart from './ui/charts/PreAssesmentDistribution.js';
import AverageStageDurationChart from './ui/charts/AverageStageDurationChart.js';
import AverageStageAppCountChart from './ui/charts/AverageStageAppCountChart.js';
import currencySymbols from 'currency-symbol-map';
import { formatToCurrency } from '../../utils/CommonFunctions.js';
import { Link } from 'react-router-dom';

const Home = () => {
  const { changeBackground } = useContext(ThemeContext);
  const [jobPostingCount, setJobPostingCount] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;
  const [currencySymbol, setCurrencySymbol] = useState('₹');
  const orgUniqueName = localStorage.getItem('organizationUniqueName');

  useEffect(() => {
    const storedCurrency = localStorage.getItem('currency');
    if (storedCurrency) {
      const symbol = currencySymbols(storedCurrency) || '₹';
      setCurrencySymbol(symbol);
    }
  }, []);

  useEffect(() => {
    changeBackground({ value: 'light', label: 'Light' });
    fetchJobPostingsCount();
  }, []);

  const accessToken =
    JSON.parse(localStorage.getItem('userDetails'))?.session.accessToken ||
    null;

  const fetchJobPostingsCount = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/analytics/job-postings-count`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      setJobPostingCount(response.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching JobPostingsCount:', error);
    }
  };

  const WindowCardData1 = {
    title1: 'Total Jobs',
    value1: jobPostingCount.totalJobsCount,
    info1: 'The total number of job postings created by your organization.',

    title2: 'Active Jobs',
    value2: jobPostingCount.activeJobsCount,
    info2:
      'The number of job postings currently active and accepting applications.',

    title3: 'Accepted Applications Per Job',
    value3: jobPostingCount.acceptedApplicationsPerJob,
    info3: 'The average number of accepted applications per job posting.',

    title4: 'Application per Job',
    value4: jobPostingCount.applicationsPerJob,
    info4: 'The average number of applications received per job posting.'
  };

  const WindowCardData2 = {
    title1: 'Total Applications',
    value1: jobPostingCount.totalApplicationsCount,
    info1: 'The total number of applications received across all job postings.',

    title2: 'In process Applications',
    value2:
      jobPostingCount.totalApplicationsCount -
      jobPostingCount.acceptedApplicationsCount -
      jobPostingCount.rejectedApplicationsCount,
    info2: 'The number of applications currently being processed.',

    title3: 'Accepted Applications',
    value3: jobPostingCount.acceptedApplicationsCount,
    info3:
      'The total number of applications accepted for further recruitment stages.',

    title4: 'Rejected Applications',
    value4: jobPostingCount.rejectedApplicationsCount,
    info4: 'The total number of applications that have been rejected.'
  };

  const WindowCardData3 = {
    title1: 'Pre Assesment Pass Rate',
    value1: jobPostingCount.preAssessmentPassRate,
    info1: 'The percentage of candidates who passed the pre-assessment stage.',

    title2: 'Candidate Drop off Rate',
    value2: jobPostingCount.candidateDropOffRate,
    info2:
      'The percentage of candidates who dropped off during the recruitment process.',

    title3: 'Amount Saved',
    value3:
      jobPostingCount.amountSaved &&
      `${formatToCurrency(jobPostingCount.amountSaved)}`,
    info3:
      'The total cost saved by automating initial recruitment steps (Rs. 250 per applicant).',

    title4: 'Total Jobs Views',
    value4: jobPostingCount.totalViews,
    info4: 'The total number of views across all job postings.'
  };

  const WindowCardData4 = {
    title1: 'Male Applicants',
    value1: jobPostingCount.genderDiversity?.male,
    info1: 'The total number of male candidates who applied for jobs.',

    title2: 'Female Applicants',
    value2: jobPostingCount.genderDiversity?.female,
    info2: 'The total number of female candidates who applied for jobs.',

    title3: 'Views Per Job Posting',
    value3: jobPostingCount.viewsPerJobPosting,
    info3: 'The average number of views each job posting receives.',

    title4: 'Application Per View',
    value4: jobPostingCount.applicationsPerView,
    info4:
      'The ratio of applications received to the number of job posting views.'
  };

  // Function to get the base URL dynamically
  const getJobPortalBaseUrl = () => {
    const currentDomain = window.location.hostname;
    const port = window.location.port;

    if (currentDomain === 'localhost') {
      return `jobs.${currentDomain}${port ? `:${port}` : ''}`;
    } else if (currentDomain.includes('dev.talentswap.ai')) {
      return 'jobs-dev.talentswap.ai';
    } else {
      return 'jobs.talentswap.ai';
    }
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-xl-12">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center gap-3">
            <div>
              <h4 className="mb-2 fs-22">Organization Dashboard</h4>
              <p className="text-muted mb-0 fs-14">
                Track your organization's recruitment metrics and analytics
              </p>
            </div>
            {orgUniqueName && (
              <a
                href={`https://${getJobPortalBaseUrl()}/${orgUniqueName}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  padding: '12px 24px',
                  borderRadius: '12px',
                  background:
                    'linear-gradient(135deg, #f5f7ff 0%, #eef1ff 100%)',
                  color: '#4318FF',
                  transition: 'all 0.3s ease',
                  fontSize: '0.95rem',
                  fontWeight: '500',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  border: '1px solid rgba(67, 24, 255, 0.1)',
                  boxShadow: '0 2px 8px rgba(67, 24, 255, 0.06)',
                  position: 'relative',
                  overflow: 'hidden',
                  width: 'fit-content',
                  maxWidth: '100%',
                  wordBreak: 'break-all'
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.background =
                    'linear-gradient(135deg, #eef1ff 0%, #e6eaff 100%)';
                  e.currentTarget.style.transform = 'translateY(-2px)';
                  e.currentTarget.style.boxShadow =
                    '0 4px 15px rgba(67, 24, 255, 0.12)';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.background =
                    'linear-gradient(135deg, #f5f7ff 0%, #eef1ff 100%)';
                  e.currentTarget.style.transform = 'translateY(0)';
                  e.currentTarget.style.boxShadow =
                    '0 2px 8px rgba(67, 24, 255, 0.06)';
                }}
              >
                <i
                  className="fas fa-globe"
                  style={{
                    fontSize: '0.9em',
                    color: '#4318FF',
                    flexShrink: 0
                  }}
                ></i>
                <span
                  className="d-inline-block text-truncate"
                  style={{
                    background: 'linear-gradient(to right, #4318FF, #868CFF)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    fontWeight: '600',
                    maxWidth: '300px',
                    '@media (max-width: 576px)': {
                      maxWidth: '150px'
                    }
                  }}
                >
                  {getJobPortalBaseUrl()}/{orgUniqueName}
                </span>
                <i
                  className="fas fa-external-link-alt"
                  style={{
                    fontSize: '0.9em',
                    color: '#4318FF',
                    flexShrink: 0
                  }}
                ></i>
              </a>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <WindowCard data={WindowCardData1} isLoading={isLoading} />
        </div>
        <div className="col-xl-6">
          <WindowCard data={WindowCardData2} isLoading={isLoading} />
        </div>

        <div className="col-xl-12">
          <ApplicationLineChart />
        </div>

        <div className="col-xl-6">
          <WindowCard data={WindowCardData3} isLoading={isLoading} />
        </div>
        <div className="col-xl-6">
          <WindowCard data={WindowCardData4} isLoading={isLoading} />
        </div>

        <div className="col-xl-12">
          <ApplicationStatusBarChart />
        </div>

        <div className="col-xl-6">
          <div className="col-xl-12">
            <CanvasPieChartTab />
          </div>
          <div className="col-xl-12">
            <AverageStageDurationChart
              chartData={jobPostingCount.averageStageDurations}
              isLoading={isLoading}
            />
          </div>
        </div>

        <div className="col-xl-6">
          <div className="col-xl-12">
            <PreAssesmentStatusChart
              chartData={jobPostingCount.preAssessmentDistribution}
              isLoading={isLoading}
            />
          </div>
          <div className="col-xl-12">
            <AverageStageAppCountChart chartData={{}} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
