import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { IoSearch } from 'react-icons/io5';
import { MdOutlineWifiTetheringErrorRounded } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

const JobApplicationBlog = ({
  applicationData,
  totalEntries,
  totalPages,
  currentPage,
  onPageChange,
  loading
}) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'REJECTED':
        return 'danger';
      case 'PROFILE_ANALYZED':
        return 'warning';
      case 'SELECTED':
        return 'success';
      default:
        return 'primary';
    }
  };

  const transformStatus = (status) => {
    // Remove curly braces and split by spaces
    const cleanedStatus = status.replace(/[{}]/g, '').split(' ');

    // Replace underscores with spaces and capitalize words
    const transformedStatus = cleanedStatus
      .map((word) =>
        word
          .replace(/_/g, ' ')
          .toLowerCase()
          .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())
      )
      .join('\n');

    return transformedStatus;
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    let items = [];
    const maxVisiblePages = 3;
    const effectiveTotalPages = Math.max(1, totalPages);

    if (effectiveTotalPages <= maxVisiblePages) {
      for (let i = 1; i <= effectiveTotalPages; i++) {
        items.push(i);
      }
    } else {
      if (currentPage <= 2) {
        items = [1, 2, 3];
      } else if (currentPage >= effectiveTotalPages - 1) {
        items = [
          effectiveTotalPages - 2,
          effectiveTotalPages - 1,
          effectiveTotalPages
        ];
      } else {
        items = [currentPage - 1, currentPage, currentPage + 1];
      }
    }

    return items;
  };

  const paginationItems = getPaginationItems();
  const effectiveTotalPages = Math.max(1, totalPages);
  const JOBS_PER_PAGE = 7;

  return (
    <>
      <div
        style={{ position: 'relative', height: '38rem' }}
        className="mb-4 overflow-auto"
      >
        <table
          id="job-table"
          className="table table-fixed display dataTablesCard mb-4 job-table table-responsive-xl card-table dataTable no-footer"
        >
          <thead>
            <tr role="user-select-none ">
              <th style={{ width: '2rem' }}>No</th>
              <th style={{ width: '10rem' }}>Name</th>
              <th style={{ width: '5rem' }}>Phone No</th>
              <th style={{ width: '13rem' }}>Email</th>
              <th style={{ width: '12rem' }}>Job Title</th>
              <th style={{ width: '6rem' }}>Applied On</th>
              <th style={{ width: '11rem' }}>Status</th>
              <th style={{ width: '5rem' }} className="text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {applicationData.map((application, index) => (
              <tr
                key={application._id}
                role="row"
                className={index % 2 === 0 ? 'odd' : 'even'}
              >
                <td style={{ maxWidth: '2rem' }} className="text-truncate">
                  {(currentPage - 1) * 7 + index + 1}.
                </td>
                <td style={{ maxWidth: '10rem' }} className="text-truncate">
                  {application.userDetails?.name || 'N/A'}
                </td>
                <td style={{ maxWidth: '5rem' }} className="text-truncate">
                  {application.userDetails?.phone || 'N/A'}
                </td>
                <td style={{ maxWidth: '13rem' }} className="text-truncate">
                  {application.userDetails?.email}
                </td>
                <td style={{ maxWidth: '12rem' }} className="text-truncate">
                  {application.jobDetails?.title || 'N/A'}
                </td>
                <td style={{ maxWidth: '6rem' }} className="text-truncate">
                  {application.applicationDate.slice(0, 10)}
                </td>

                <td style={{ maxWidth: '11rem' }}>
                  <span
                    className={`badge badge-lg light badge-${getStatusColor(
                      application.status
                    )}`}
                  >
                    {transformStatus(application.status)}
                  </span>
                </td>
                <td style={{ maxWidth: '5rem' }} className="text-center">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>View Application</Tooltip>}
                  >
                    <Link
                      to={`/job-application-view/${application.job}/${application._id}`}
                    >
                      <FaEye size={18} />
                    </Link>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}

            {/* Adding empty filler rows to keep the height of table same */}
            {applicationData &&
              applicationData.length > 0 &&
              [
                ...Array(Math.max(0, JOBS_PER_PAGE - applicationData.length))
              ].map((_, i) => (
                <tr key={`filler-${i}`} style={{ height: '4.5rem' }}>
                  <td colSpan="9"></td>
                </tr>
              ))}
          </tbody>
        </table>
        {loading === 1 ? (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10
            }}
            className="d-flex align-items-center justify-content-center"
          >
            <Spinner animation="border" style={{ color: '#858585' }} />
          </div>
        ) : loading === 2 ? (
          <div className="d-flex flex-column align-items-center mt-5">
            <MdOutlineWifiTetheringErrorRounded size={120} color="#878787B7" />
            <h2>Error fetching data</h2>
            <h3 style={{ color: '#828282' }}>
              Check your network and try again
            </h3>
          </div>
        ) : loading === 0 && applicationData.length === 0 ? (
          <div className="d-flex flex-column align-items-center mt-5">
            <IoSearch size={120} color="#878787B7" />
            <h2>No results found</h2>
            <h3 style={{ color: '#828282' }}>Change your Job and try again</h3>
          </div>
        ) : null}
      </div>

      {applicationData.length > 0 && (
        <div className="d-md-flex d-block align-items-center justify-content-between text-center  flex-wrap mt-md-0 mt-3">
          <div className="mb-md-0 mb-3">
            <p className="dataTables_info">
              {`Showing ${
                totalEntries !== 0 ? 7 * (currentPage - 1) + 1 : 0
              } to ${Math.min(
                7 * currentPage,
                totalEntries
              )} of ${totalEntries} entries`}
            </p>
          </div>
          <nav>
            <ul className="pagination pagination-circle justify-content-center">
              <li className="page-item page-indicator">
                <button
                  onClick={handlePreviousPageClick}
                  disabled={currentPage === 1}
                  className="page-link"
                  style={{
                    backgroundColor: currentPage === 1 ? '#ECECEC' : '',
                    cursor: currentPage === 1 && 'not-allowed'
                  }}
                >
                  <FaAngleLeft
                    style={{ color: currentPage === 1 ? '#616161' : '' }}
                  />
                </button>
              </li>
              {paginationItems[0] > 1 && (
                <li className="page-item d-flex align-items-end">
                  <label className="fs-4">...</label>
                </li>
              )}
              {paginationItems.map((item) => (
                <li
                  key={item}
                  className={`page-item ${
                    item === currentPage ? 'active' : ''
                  }`}
                  onClick={() => onPageChange(item)}
                >
                  <Button className="page-link">{item}</Button>
                </li>
              ))}
              {paginationItems[paginationItems.length - 1] <
                effectiveTotalPages && (
                <li className="page-item d-flex align-items-end">
                  <label className="fs-4">...</label>
                </li>
              )}
              <li className="page-item page-indicator">
                <button
                  onClick={handleNextPageClick}
                  disabled={currentPage >= effectiveTotalPages}
                  className="page-link"
                  style={{
                    backgroundColor:
                      currentPage >= effectiveTotalPages ? '#ECECEC' : ''
                  }}
                >
                  <FaAngleRight
                    style={{
                      color:
                        currentPage >= effectiveTotalPages ? '#616161' : '',
                      cursor:
                        currentPage >= effectiveTotalPages && 'not-allowed'
                    }}
                  />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};

export default JobApplicationBlog;
